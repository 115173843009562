import React from "react"
import { Link } from "gatsby"

const Nav = ({children}) => {
  return (
    <nav className="navbar has-background-primary has-text-light is-fixed-top">
      {children}
    </nav>
  )
}

const NavBrand = ({isActive, onChange, children}) => {
  return (
    <div className="navbar-brand" style={{alignItems: "center"}}>
      <a className="navbar-item has-text-centered-mobile" href="#">
        {children}
      </a>

      <a role="button" className={`has-text-light navbar-burger ${isActive}`} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onClick={onChange}>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
  )
}

const NavMenu = ({isActive, children}) => {
  return (
    <div id="navMenu" className={`has-background-primary navbar-menu ${isActive}`}>
      {children}
    </div>
  )
}

const NavItem = ({to=null, className="", isActive="", onChange="", children}) => {
  return (
    <Link to={to} className={`navbar-item has-text-light ${className}`}>
      {children}
    </Link>
  )
}

const NavStart = ({isActive="", onChange="", children}) => {
  return (
    <div className="navbar-start">
      {children}
    </div>
  )
}

const NavEnd = ({isActive="", onChange="", children}) => {
  return (
    <div className="navbar-end">
      {children}
    </div>
  )
}




export {Nav, NavStart, NavEnd, NavItem, NavBrand, NavMenu}
