import React, {useState, useEffect} from "react"
import { graphql, Link } from "gatsby"
import Helmet from 'react-helmet';
import {MainLogo, NavLogo} from 'components/logo';
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import Hero  from 'sections/hero'
import {Columns, Col} from 'sections/columns'
import Section from 'sections/section';
import Footer from 'sections/footer';
import { TextInput, EmailInput, TextAreaInput, SubmitButton, Form } from "sections/form";
import {Nav, NavItem, NavStart, NavEnd, NavMenu, NavBrand} from 'sections/navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun, faDiamondTurnRight, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { Text } from 'sections/text';
import { zonedTimeToUtc, format } from 'date-fns-tz';
import { ContactForm } from 'components/contactForm';

import 'styles/index.scss'


const IndexPage = () => {
  const [temperature, setTemperature] = useState(30);
  useEffect(() => {
    fetch(process.env.GATSBY_ABAM_API_METEO_URL,
         {headers:{'Authorization': `Token ${process.env.GATSBY_ABAM_API_TOKEN}`}})
         .then(response => response.json())
         .then(data => {
            if (data.length)
              setTemperature(data[0]["temperature"]);
         })
    const id = setInterval(() =>
      fetch(process.env.GATSBY_ABAM_API_METEO_URL,
           {headers:{'Authorization': `Token ${process.env.GATSBY_ABAM_API_TOKEN}`}})
           .then(response => response.json())
           .then(data => {
              if (data.length)
                setTemperature(data[0]["temperature"]);
           }), process.env.GATSBY_ABAM_API_REFRESH_INTERVAL * 1000);
    return () => clearInterval(id);
  }, []);
  const headerContent = (
    <div className="content subtitle has-text-light" style={{fontWeight: 500}}>
      <button className="button is-primary mb-5 is-size-5">
        <Link to="#reservation" className="link-underline">
        Réserver
        </Link>
      </button>
    </div>
  )
  const headerTitle = (
    <>
    <div className="content subtitle has-text-light" style={{fontWeight: 500}}>
      <p>L’ambiance décontractée d’une paillote de charme</p>
      <p><a href="https://goo.gl/maps/BbKaJZx3Ka321uYd9"  target="_blank" rel="noopener noreferrer" className="link-underline">42°33'24" N, 8°45'49" E</a></p>
    </div>
    <h1 className="title is-1 has-text-light has-text-weight-bold mb-0" style={{fontSize: "4rem"}}>{temperature}°C</h1>
    </>
  )
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const isActive = isNavbarOpen ? "is-active" : "";

  return (
    <>
    <Helmet defer={false}>
      <title>La Licorne, restaurant de plage à Calvi</title>
      <meta name="description" content="La Licorne, restaurant de plage à Calvi. Découvrez notre site web avec notre carte des menus" />
      <meta name="viewport" content="width=device-width, initial-scale = 1.0, maximum-scale=1.0, user-scalable=no" />
    </Helmet>
    <Nav>
      <NavBrand isActive={isActive} onChange={() => setIsNavbarOpen(!isNavbarOpen)}>
        <NavLogo />
      </NavBrand>
      <NavMenu isActive={isActive}>
      <NavStart>
        <NavItem className="link-underline" to="#histoire">
          L'Histoire
        </NavItem>
        <NavItem to="#plage" className="link-underline">
          La Plage
        </NavItem>
        <NavItem to="#restaurant" className="link-underline">
          Le Restaurant
        </NavItem>
        <NavItem to="/menu" className="link-underline">
          La Carte
        </NavItem>
      </NavStart>
      <NavEnd>
        <NavItem>
          <div className="buttons">
            <a href="https://goo.gl/maps/BbKaJZx3Ka321uYd9" target="_blank" rel="noopener noreferrer" className="button is-light has-text-black">
              <FontAwesomeIcon icon={faDiamondTurnRight} /> &nbsp; Y-aller
            </a>
            <a href="tel:+33495650862" className="button is-phone"><FontAwesomeIcon icon={faPhone} /> &nbsp; 04 95 65 08 62</a>
          </div>
        </NavItem>
      </NavEnd>
      </NavMenu>
    </Nav>
    <Section hasContainer={false} className="px-0">
      <Hero image="/plage.jpg" size="is-fullheight" title={headerTitle} content={headerContent} />
    </Section>
    <Section hasContainer={false} className="px-0" id="histoire">
    <Columns>
      <Col className="is-offset-1 is-5">
        <Columns>
        <Col className="is-10">
        <StaticImage src="../images/restaurant.jpg" alt="restaurant" />
        </Col>
        </Columns>
      </Col>
      <Col isSpacedEven className="is-5 is-size-5 px-3">
        <h2 className="title is-2 has-text-primary px-5-mobile">L'Histoire</h2>
        <div className="content has-text-justified px-5-mobile">
          <p>
          Vous arrivez sur la plage de Calvi et une chose vous frappe : <br/>La symbiose de cet endroit unique au monde, les 6 km de plage de sable fin qui s’étendent devant vous, sa palette de couleurs qui semble infinie et cette lumière qui immédiatement vous envahie et vous apaise.
          <br/>De part et d’autre de cette étendue les majestueuses montagnes corses semblent se dresser devant la citadelle de Calvi rappelant aux hommes qu’ici la nature est reine.
          </p>
          <p>
          C’est au cours des années 60 que les premières paillottes sont apparues sur la plage calvaise. La Licorne quand a elle a vu le jour en 1978 et ne cessa d’évoluer depuis.
          <br/>Sous sa nouvelle forme elle revêt une parure de diverses essences de bois et de matières naturelles qui l’intègrent parfaitement au paysage.
          </p>
        </div>
        <div></div>
      </Col>
    </Columns>
    </Section>
    <Section hasContainer={false} id="restaurant" className="px-0">
    <Hero image="/restaurant.jpg" size="is-large" />
    </Section>
    <Section hasContainer={false} className="px-0">
      <Columns>
      <Col className="is-offset-1 is-10">
      <h2 className="title is-2 has-text-primary px-5-mobile">La Licorne X Terracotta</h2>
      <div className="content has-text-justified px-5-mobile">
      <p>
      La Licorne c’est une Cabane au charme unique, chic et décontractée elle est habillée d’une décoration envoutante conçue par <a href="https://www.instagram.com/terracotta.calvi" target="_blank" rel="noopener noreferrer" className="has-text-primary has-text-weight-bold">@terracotta.calvi</a>
      </p>
      </div>
      <Columns>
        <Col className="has-text-left">
          <figure className="image is-square is-fullwidth">
          <StaticImage className="has-ratio" src="../images/terracotta1.jpg" alt="terracotta1"  />
          </figure>
        </Col>
        <Col className="has-text-centered">
          <figure className="image is-square is-fullwidth">
          <StaticImage  className="has-ratio" src="../images/terracotta2.jpg" alt="terracotta2"  />
          </figure>
        </Col>
        <Col  className="has-text-right">
          <figure className="image is-square is-fullwidth">
          <StaticImage  className="has-ratio" src="../images/terracotta3.jpg" alt="terracotta3"  />
          </figure>
        </Col>
      </Columns>
      </Col>
      </Columns>
    </Section>
    <Section id="plage" className="px-0" hasContainer={false}>
      <Columns>
        <Col className="is-half">
          <Columns>
          <Col className="is-11">
          <figure className="image is-16by9 is-fullwidth">
            <StaticImage className="has-ratio" src="../images/plage.jpg" alt="plage"/>
          </figure>
          </Col>
          </Columns>
        </Col>
        <Col isSpacedEven className="is-5 is-size-5">
          <h2 className="title is-2 has-text-primary px-5-mobile">La Plage</h2>
          <div className="content has-text-justified px-5-mobile">
            <p>Différents espaces de détente sont proposés pour garantir des moments de partage et de convivialité en famille ou entre amis.</p>
            <p>Le service sur les transats est assuré toute la journée. Le soir venu une atmosphère chaleureuse se déploie sur la plage, on prend l’apéro les pieds dans le sable. Les repas s’éternisent au clair de lune, le temps n’a plus d'emprise !</p>
          </div>
          <div></div>
        </Col>
      </Columns>
    </Section>
    <Section hasContainer={false} className="px-0">
      <Columns>
      <Col className="is-offset-1 is-10">
      <Columns>
        <Col className="has-text-left">
          <figure className="image is-square is-fullwidth">
          <StaticImage className="has-ratio" src="../images/plage1.jpg" alt="plage1"  />
          </figure>
        </Col>
        <Col className="has-text-centered">
          <figure className="image is-square is-fullwidth">
          <StaticImage  className="has-ratio" src="../images/plage2.jpg" alt="plage2"  />
          </figure>
        </Col>
        <Col  className="has-text-right">
          <figure className="image is-square is-fullwidth">
          <StaticImage  className="has-ratio" src="../images/plage3.jpg" alt="plage3"  />
          </figure>
        </Col>
      </Columns>
      </Col>
      </Columns>
    </Section>
    <Section className="px-0" hasContainer={false}>
    <Columns>
      <Col isSpacedEven className="is-offset-1 is-5 is-size-5">
        <h2 className="title is-2 has-text-primary px-5-mobile">Le Restaurant</h2>
        <div className="content has-text-justified px-5-mobile">
        <p>La Licorne est une paillote de charme qui vous accueille tous les jours de 9h à 22h.</p>
        <p>L’ambiance est décontractée et familiale.</p>
        <p>Le restaurant propose une carte méditerranéenne avec une attention portée sur la présentation et le service attentionné.</p>
        </div>
        <div className="has-text-centered">
        <button className="button is-primary is-size-4">
          <Link to="/menu" className="link-underline">
          La Carte
          </Link>
        </button>
        </div>
      </Col>
      <Col className="is-half">
        <Columns>
          <Col className="is-offset-1">
            <figure className="image is-fullwidth is-16by9">
              <StaticImage className="has-ratio" src="../images/restaurant2.jpg" alt="bar"/>
            </figure>
          </Col>
        </Columns>
      </Col>
    </Columns>
    </Section>
    <Section className="px-0" hasContainer={false}>
    <Columns>
      <Col className="is-half">
        <Columns>
          <Col className="is-11">
            <figure className="image is-fullwidth is-16by9">
              <StaticImage className="has-ratio" imgStyle={{objectPosition: "bottom"}} src="../images/bar.jpg" alt="bar"/>
            </figure>
          </Col>
        </Columns>
      </Col>
      <Col isSpacedEven className="is-5 is-size-5">
        <div>
        <h2 className="title is-2 has-text-primary px-5-mobile">Le Bar</h2>
        <div className="content has-text-justified px-5-mobile">
          <p>Un tour du monde des saveurs, rafraîchissantes, désaltérantes, des boissons incontournables aux cocktails signatures , avec ou sans alcool.</p>
        </div>
        <div></div>
        </div>
      </Col>
    </Columns>
    </Section>
    <Section id="reservation">
        <h2 className="title is-2 has-text-primary">Une demande particulière ?</h2>
        <div className="content has-text-justified">
          <p>
            Vous souhaitez organiser une soirée exceptionnelle ? Réservez en exclusivité vos événements.
            <br/>Menus et animations sur mesure.
          </p>
        </div>
        <ContactForm />
    </Section>
    <Footer />
    </>
  )
}

export default IndexPage
