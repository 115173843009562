import * as React from "react"

const Form = ({onSubmit, children}) => {
  return (
    <form onSubmit={onSubmit}>
      {children}
    </form>
  )
}

const Field = ({children}) => {
  return (
    <div className="field">
      {children}
    </div>
  )
}

const TextInput = ({name, label, placeholder, register, required = false}) => {
  return (
    <Field>
      <label className="label">{label}</label>
      <input className="input" type="text" name={name} required={required} placeholder={placeholder} {...register(name, {required: required})} />
    </Field>
  )
}

const EmailInput = ({name, label, placeholder, register, required = false}) => {
  return (
    <Field>
      <label className="label">{label}</label>
      <input className="input" type="email" name={name} required={required}  placeholder={placeholder} {...register(name, {required: required})} />
    </Field>
  )
}

const TextAreaInput = ({name, label, placeholder, register, required = false}) => {
  return (
    <Field>
      <label className="label">{label}</label>
      <textarea className="textarea" name={name} required={required} placeholder={placeholder} {...register(name, {required: required})} />
    </Field>
  )
}

const SubmitButton = ({className = "", disabled = false, children}) => {
  return (
    <Field>
      <button disabled={disabled} className={`button is-primary ${className}`} type="submit">{children}</button>
    </Field>
  )
}

export {Form, Field, TextInput, EmailInput, TextAreaInput, SubmitButton}
