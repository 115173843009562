import * as React from "react"

const Section = ({hasContainer = true, className = "", id, children}) => {
  return (
    <section className={`section has-background-light ${className}`} id={id}>
      {hasContainer ? <div className="container is-max-widescreen">{children}</div> : children}
    </section>
  )
}

export default Section;