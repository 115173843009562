import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { TextInput, EmailInput, TextAreaInput, SubmitButton, Form } from "sections/form";

export const ContactForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [success, setSuccess] = useState(false);
  const onSubmit = (data) => {
    setSuccess(false);
    return fetch(
      process.env.GATSBY_ABAM_API_CONTACT_URL,
      {
        headers: {'Authorization': `Token ${process.env.GATSBY_ABAM_API_TOKEN}`, "Content-Type": "application/json"},
        method: 'POST',
        body: JSON.stringify(data)
      },
    ).then(response => response.json()).then(() => setSuccess(true))
  }
  const isDisabled = process.env.GATSBY_ABAM_DISABLE_CONTACT || false;
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {isDisabled && <div className="notification is-warning">Suite à une très forte demande le système de réservation est momentanément hors service</div>}
      {success && <div className="notification is-success">Votre message a bien été envoyé.</div>}
      <TextInput placeholder="Nom" name="name" register={register} required />
      <EmailInput placeholder="Email" name="email" register={register} required />
      <TextInput placeholder="Téléphone" name="phone" register={register} required />
      <TextAreaInput placeholder="Message" name="message" register={register} required />
      <SubmitButton disabled={isDisabled} className="link-underline">Envoyer</SubmitButton>
    </Form>
  )
}