import * as React from "react"

const Hero = ({header = null, size = "", image, title = null, content = null, footer = null }) => {
  var background = "";
  var boxShadow = "";
  if(title || content) {
    background = "linear-gradient(to left, rgba(0,0,0,.1), rgba(0,0,0,.25) 25%, rgba(0,0,0,.25) 75%, rgba(0,0,0,.1) 100%),";
    boxShadow = "inset 0 0 0 1000px rgba(0,0,0,.2)";
  }
  return (
    <section className={`hero has-background-light ${size}`}>
    <div className="hero-head">
      {header}
    </div>
    <div className="hero-body" style={{backgroundImage: `${background} url(${image})`, backgroundSize: "cover", backgroundPosition: "center", boxShadow: boxShadow, minHeight: 450}}>
      {(title || content) &&
      <div className="hero-content container has-text-centered" style={{marginTop: "-10rem"}}>
        {title}
        <br/>
        {content}
        </div>
      }
    </div>
    <div className="hero-footer">
      {footer}
    </div>
  </section>
  )
}

export default Hero
